import {
  getModule,
  Module,
  MutationAction,
  VuexModule
} from "vuex-module-decorators";
import store from "@/store";
import { DetailResponse, DetailResult } from "@/api/schedule/response";
import * as ScheduleAPI from "@/api/schedule";
import { isSuccess } from "@/api/response";
import { DetailRequest } from "@/api/schedule/request";

const MODULE_NAME = "schedule/detail";

/**
 * アプリ内通知設定取得API（/detail-schedule）のレスポンスを管理するストアモジュール
 */
@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class Detail extends VuexModule {
  // state
  detailResponse: DetailResponse = {} as DetailResponse;

  // getters
  get isSuccess() {
    return isSuccess(this.detailResponse);
  }

  get getMessage() {
    return this.detailResponse.message;
  }

  get getDetail() {
    return this.detailResponse.results || ({} as DetailResult);
  }

  // MutationActions
  @MutationAction
  async detail(detailRequest: DetailRequest) {
    const detailResponse = await ScheduleAPI.detail(detailRequest);
    return {
      detailResponse
    };
  }

  @MutationAction
  async clearResponse() {
    return {
      detailResponse: {} as DetailResponse
    };
  }
}

export default getModule(Detail);
